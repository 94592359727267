import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import { Tree, Row } from 'antd';
import Router, { withRouter } from 'next/router';
import CatalogPriceRange from './components/CatalogPriceRange';

import { resetRangeFilter, setRangeFilter } from '../../store/actions/filters.actions';
import { resetCatalog } from '../../store/actions/catalog.actions';

const { TreeNode } = Tree;

class ProductFilters extends Component {
  componentDidMount() {
    /* custom scroll always to top with page change pagination */
    const container = document.getElementsByTagName('BODY')[0];
    container.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onChange = () => {};

  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.url} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} key={item.url} dataRef={item} />;
    });

  goToCategory = (value, selectedNode) => {
    const {
      // redrawKey,
      resetProductCatalog,
      catalog,
      resetPriceRange
      // resetBrands,
      // resetTags
    } = this.props;
    const { query } = this.props.router;
    const { url } = selectedNode.node.props.dataRef;
    // redrawKey();
    resetProductCatalog(catalog.parentCategoryUrl);
    resetPriceRange();
    // resetBrands();
    // resetTags();
    Router.replace(
      `/catalog?location=${url}${
        query.searchtext ? `&searchtext=${query.searchtext}` : ''
      }`,
      `/catalog?location=${url}${
        query.searchtext ? `&searchtext=${query.searchtext}` : ''
      }`,
      { shallow: false }
    );
  };

  // eslint-disable-next-line consistent-return
  drawProductPriceRange = (item) => {
    // eslint-disable-next-line default-case
    switch (item.range) {
      case '0-20':
        return (
          <span>
            0 - 20€ <span className="checkbox-total">({item.count})</span>
          </span>
        );
      case '20-50':
        return (
          <span>
            20 - 50€ <span className="checkbox-total">({item.count})</span>
          </span>
        );
      case '50':
        return (
          <span>
            50€ & άνω <span className="checkbox-total">({item.count})</span>
          </span>
        );
    }
  };

  onChangePriceRange = async (value) => {
    const { setFilterPriceRange } = this.props;
    await setFilterPriceRange(value);
  };

  onChangePriceRangeComplete = async () => {
    const { applyFilters } = this.props;
    const { filters, pricesRange } = this.props;
    if (
      filters?.pricesRange?.max !== pricesRange?.max ||
      filters?.pricesRange?.min !== pricesRange?.min
    ) {
      applyFilters(null, true, true);
    }
  };

  render() {
    const { filters, treeData, search, settings, t, pricesRange } = this.props;
    const { query } = this.props.router;
    // console.log('QQQQQQQQ', treeData);
    // console.log(`test:: ${treeData}`);

    return (
      <div className="filters-container">
        <div className="filters-widget">
          <CatalogPriceRange
            filters={filters}
            onChangePriceRange={this.onChangePriceRange}
            onChangePriceRangeComplete={this.onChangePriceRangeComplete}
            pricesRange={pricesRange}
            settings={settings}
            search={search}
            t={t}
          />
        </div>
        {treeData?.length > 0 ? (
          <Row className="mt-50">
            <p>Υποκατηγορίες</p>
          </Row>
        ) : null}
        <Tree
          defaultExpandAll
          className="filters-categories-tree"
          selectedKeys={[query.location]}
          onSelect={this.goToCategory}
        >
          {treeData?.length > 0
            ? this.renderTreeNodes(sortBy(treeData, 'weight', 'asc'))
            : null}
        </Tree>
      </div>
    );
  }
}

ProductFilters.getInitialProps = async () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPriceRange: () => dispatch(resetRangeFilter()),
    resetProductCatalog: (parentUrl) => dispatch(resetCatalog(parentUrl)),
    setFilterPriceRange: (pricesRange) => dispatch(setRangeFilter(pricesRange))
  };
};

const mapStateToProps = (state) => {
  return {
    catalog: state.catalog,
    filters: state.filters,
    search: state.search
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductFilters));
