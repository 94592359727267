import { Button, Col, Icon, Row, Spin } from 'antd';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import { formatCurrency, getImageLG, truncateText } from '../../../utils/utils';
import '../../Product/styles/mobile.less';

const ProductShort = ({
  addProductToCart,
  addProductToFavorites,
  favorites,
  origin,
  mediaType,
  product,
  settings,
  t
}) => {
  const isFavorite = favorites?.some((favorite) => favorite === product._id);
  const [isProductNew, setProductNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const loader = (
    <Icon
      type="loading"
      style={{ fontSize: 30, color: '#679964', marginLeft: '40px' }}
      spin
    />
  );

  useEffect(() => {
    if (product.createdAt === undefined) return;

    const currentDate = () => {
      if (product.createdAt === undefined) return;

      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (day < 10) {
        day = `0${day}`;
      }
      if (month < 10) {
        month = `0${month}`;
      }

      const fullDate = `${month}/${day}/${year}`;
      // console.log(fullDate, "currentDate")
      return fullDate;
    };

    const productCreatedDate = () => {
      if (product.createdAt === undefined) return;

      const index = product.createdAt.indexOf('T');
      let createdAt = product.createdAt.slice(0, index);
      const year = createdAt.slice(0, 4);
      const month = createdAt.slice(5, 7);
      const day = createdAt.slice(8);
      createdAt = `${month}/${day}/${year}`;
      // console.log(createdAt, "createdDate")
      return createdAt;
    };

    const dateProductCreated = new Date(productCreatedDate());
    const dateToday = new Date(currentDate());

    const timeDifference = dateToday.getTime() - dateProductCreated.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    if (daysDifference < 30) {
      setProductNew(true);
    }
  }, []);

  // const getAvaliability = (value) => {
  //   // eslint-disable-next-line default-case
  //   switch (value) {
  //     case 'direct':
  //       return t('Availability.direct');
  //     case 'order':
  //       return t('Availability.order');
  //     case '1-3':
  //       return t('Availability.1-3');
  //     case '4-10':
  //       return t('Availability.4-10');
  //   }
  // };
  return (
    <Col
      className={[
        'mr-10 product-short-container',
        origin === 'slider' ? 'list-item-nw100' : 'list-item'
      ].join(' ')}
    >
      <Row className="list-content">
        {/* image container */}
        <Col className="bordered-image">
          <Link
            href={`/product?slug=${encodeURIComponent(product.slug)}`}
            as={`/product/${product.slug}`}
          >
            <a>
              <img alt="product" className="center-image" src={getImageLG(product)} />
            </a>
          </Link>
        </Col>
        {/* products description and more */}
        <Col className="mt-20" span={24}>
          <Row type="flex" justify="start">
            <Col span={24} style={{ minHeight: 19 }}>
              {product.stock < 3 && product.stock > 0 ? (
                <span className="limited-quantity">{t('limitedQuantity')}</span>
              ) : null}
              {product.stock === 0 ? (
                <span className="text-14-red">{t('outOfStock')}</span>
              ) : null}
            </Col>
          </Row>
        </Col>
        {/* product information container */}
        <Col span={24}>
          <Row type="flex" justify="start" align="middle">
            <Col align="left" span={24}>
              <Link
                href={`/product?slug=${encodeURIComponent(product.slug)}`}
                as={`/product/${product.slug}`}
              >
                <a className="text-18-bold-link product-title">
                  {truncateText(product.title, 70)}
                </a>
              </Link>
            </Col>
            <Col style={{ height: 20 }} span={24}>
              {product.sku ? (
                <span className="text-14-mute text-light">
                  {t('sku')}
                  {product.sku}
                </span>
              ) : null}
            </Col>
            <Col span={12} className="availabilityContainer">
              {/* <Row type="flex">
                <Col span={24}>
                  <span className="text-14-normal">
                    {mediaType === 'desktop'
                      ? getAvaliability(product.availability?.value)
                      : truncateText(getAvaliability(product.availability?.value), 28)}
                  </span>
                </Col>
              </Row> */}
              <Row type="flex">
                <Col>
                  <span className="text-14-normal">
                    {mediaType === 'desktop'
                      ? t(`Availability.${product.availability?.value}`)
                      : truncateText(
                          t(`Availability.${product.availability?.value}`),
                          28
                        )}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                {/* <Col>
                  <span className="text-15-normal">{product.availability?.label}</span>
                </Col> */}
              </Row>
              <Row type="flex" justify="space-between" align="middle">
                <Col className="d-flex-start">
                  {product.onSale.value ? (
                    <>
                      <div className="line-through pt-1">
                        <span className="text-16-normal m-0 text-light">{t('from')}</span>
                        <span className="text-20-mute text-light pr-2">
                          {formatCurrency(product.price, settings)}
                        </span>
                      </div>
                      <span className="text-24-mute text-black pl-1">
                        {formatCurrency(product.sale.salePrice, settings).split(',')[0]},
                      </span>
                      <span className="text-24-mute text-black pr-1">
                        {
                          formatCurrency(product.sale.salePrice, settings)
                            .split(',')[1]
                            .split(' ')[0]
                        }
                      </span>
                      <span className="text-24-mute text-black pr-1 ">
                        {formatCurrency(product.sale.salePrice, settings).split(' ')[1]}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-24-mute text-black pl-1">
                        {formatCurrency(product.price, settings).split(',')[0]},
                      </span>
                      <span className="text-24-mute text-black pr-1">
                        {
                          formatCurrency(product.price, settings)
                            .split(',')[1]
                            .split(' ')[0]
                        }
                      </span>
                      <span className="text-24-mute text-black pr-1 ">
                        {formatCurrency(product.price, settings).split(' ')[1]}
                      </span>
                    </>
                  )}
                </Col>
                {product.onSale.value ? (
                  <div>
                    <div className="circle-item-grid text-white">
                      -{Math.round(product.sale.percentage)}%
                    </div>
                  </div>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Col>
        {/* add to cart container new product */}
        <Col className="mt-10" span={24}>
          <Row
            justify={isProductNew ? 'space-between' : 'end'}
            align="bottom"
            type="flex"
          >
            {isProductNew ? (
              <Col className="subtitle-text bold">
                <p className="subtitle-text bold">{t('newProduct')}</p>
              </Col>
            ) : null}
            <Col>
              <Row justify="center" type="flex">
                <Col className="mr-10">
                  {!isFavorite ? (
                    <Button
                      htmlType="button"
                      className="outlined-button-product-icon"
                      onClick={() => addProductToFavorites(product)}
                    >
                      <Icon type="heart" style={{ fontSize: 18 }} />
                    </Button>
                  ) : (
                    <Button
                      htmlType="button"
                      className="outlined-button-product-icon"
                      onClick={() => addProductToFavorites(product)}
                    >
                      <Icon
                        style={{ color: 'red', fontSize: 18 }}
                        type="heart"
                        theme="filled"
                      />
                    </Button>
                  )}
                </Col>
                <Col>
                  {/* <Button onClick={addProductToCart} className="cart-button-product-icon">
                    <Icon type="shopping-cart" style={{ fontSize: 22 }} />
                  </Button> */}
                  {product.variants !== undefined && product.variants.length > 0 ? (
                    <Col>
                      {loading ? (
                        <Spin indicator={loader} />
                      ) : (
                        <Link
                          href={`/product?slug=${encodeURIComponent(product.slug)}`}
                          as={`/product/${product.slug}`}
                        >
                          <Button
                            className="cart-button-product-icon"
                            disabled={product.availability.value === 'notAvailable'}
                            // style={{ width: '100px' }}
                            onClick={() => setLoading(true)}
                          >
                            <Icon type="shopping-cart" style={{ fontSize: 22 }} />
                          </Button>
                        </Link>
                      )}
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        disabled={product.availability.value === 'notAvailable'}
                        onClick={addProductToCart}
                        className="cart-button-product-icon"
                      >
                        <Icon type="shopping-cart" style={{ fontSize: 22 }} />
                      </Button>
                    </Col>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ProductShort;
