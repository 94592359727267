import { Drawer, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import CatalogDesktop from './components/Desktop/CatalogDesktop';
// import CatalogMobile from './components/Mobile/CatalogMobile';
import FiltersContainer from './FiltersContainer';
import './index.less';

const ProductCatalog = ({
  applyFilters,
  brands,
  catalog,
  categories,
  changeSortOrder,
  filters,
  settings,
  loading,
  pricesRange,
  products,
  t,
  tags,
  redrawKey
}) => {
  const [filterDrawer, setFilterDrawer] = useState(false);
  const closeDrawer = useCallback(() => setFilterDrawer(false), [setFilterDrawer]);

  return (
    <>
      <div className="catalog-container">
        <CatalogDesktop
          applyFilters={applyFilters}
          brands={brands}
          catalog={catalog}
          categories={categories}
          changeSortOrder={changeSortOrder}
          filters={filters}
          loading={loading}
          pricesRange={pricesRange}
          products={products}
          settings={settings}
          t={t}
          setFilterDrawer={setFilterDrawer}
          tags={tags}
        />
      </div>
      {/* filter drawer */}
      <Drawer
        className="drawer-90"
        placement="left"
        closable="true"
        onClose={closeDrawer}
        visible={filterDrawer}
      >
        <Row>
          <div className="ml-20">
            <FiltersContainer
              filters={filters}
              applyFilters={applyFilters}
              brands={brands}
              pricesRange={pricesRange}
              hasProducts={products.length > 0}
              settings={settings}
              t={t}
              tags={tags}
              treeData={categories}
              redrawKey={redrawKey}
            />
          </div>
        </Row>
      </Drawer>
    </>
  );
};

export default ProductCatalog;
