import React from 'react';
import '../../index.less';
import { Button, Col, Row, Select, notification, Divider } from 'antd';
import { connect } from 'react-redux';
import { addToTempCart } from '../../../../store/actions/cart.actions';
import { addToFavorite } from '../../../../store/actions/customer.actions';
import { transformProduct } from '../../../../utils/utils';
import { Media } from '../../../../Media';
import ProductShort from '../../../shared/Product/ProductShort';
import ProductLong from '../../../shared/Product/ProductLong';
import FiltersContainer from '../../FiltersContainer';

const { Option } = Select;

const CatalogDesktop = ({
  addToCart,
  addToFavorites,
  applyFilters,
  brands,
  catalog,
  changeSortOrder,
  customer,
  favorites,
  filters,
  pricesRange,
  products,
  reduxProducts,
  loading,
  settings,
  categories,
  t,
  tags,
  setFilterDrawer
}) => {
  const addProductToCart = (product) => {
    const index = reduxProducts.findIndex((item) => product._id === item._id);
    let quantity = 0;
    if (index > -1) {
      quantity = reduxProducts[index].quantity;
    }

    if ((quantity > product.stock - 1 && product.stock) || product.stock === 0) {
      return notification.error({
        message: t('outOfStock'),
        className: 'custom-notification'
      });
    }

    addToCart(transformProduct(product), 1, quantity);
    return notification.success({
      message: t('productAddedToCart'),
      className: 'custom-notification'
    });
  };

  return (
    <>
      <Row type="flex" gutter={10}>
        {/* filters */}
        <Media greaterThanOrEqual="lg">
          <Col span={24}>
            {!loading ? (
              <FiltersContainer
                filters={filters}
                applyFilters={applyFilters}
                brands={brands}
                pricesRange={pricesRange}
                hasProducts={products.length > 0}
                settings={settings}
                t={t}
                tags={tags}
                treeData={categories}
              />
            ) : null}
          </Col>
        </Media>
        <Media className="mobile-filters-container mb-20" lessThan="lg">
          <Col span={24}>
            <Button
              className="light-btn-fat"
              onClick={() => {
                setFilterDrawer(true);
              }}
            >
              {t('filters')}
            </Button>
          </Col>
        </Media>
        {/* divider */}

        <Col className="ml-50 mr-05 catalog-divider">
          <Divider type="vertical" />
        </Col>
        {/* filters and products */}
        <Col xs={24} xl={18}>
          <Row className="mb-50" type="flex" justify="end" align="middle">
            {!loading ? (
              <Col xs={12} sm={6} xl={4} className="mb-3">
                <Select
                  className="transparent-select"
                  placeholder={
                    <p className="text-16-normal">{t('sort.priceAscending')}</p>
                  }
                  onChange={changeSortOrder}
                  value={catalog.orderType}
                >
                  <Option value="priceAscending">
                    <p className="text-16-normal">{t('sort.priceAscending')}</p>
                  </Option>
                  <Option value="priceDescending">
                    <p className="text-16-normal">{t('sort.priceDescending')}</p>
                  </Option>
                  {/* <Option value="alphaDescending">
                    <p className="text-16-normal">{t('sort.alphaDescending')}</p>
                  </Option>
                  <Option value="alphaAscending">
                    <p className="text-16-normal">{t('sort.alphaAscending')}</p>
                  </Option> */}
                  {/* <Option value="ratings">{t('sort.ratings')}</Option> */}
                </Select>
              </Col>
            ) : null}
          </Row>

          {/* products */}
          <Row type="flex" justify="start">
            {products.length > 0
              ? products.map((product) => {
                  return product.categories.some((item) => item.layout === 'list') ? (
                    <Col key={product._id} xs={24} sm={24} lg={24} className="list mb-40">
                      <ProductLong
                        addProductToCart={() => addProductToCart(product)}
                        addProductToFavorites={() => addToFavorites(product._id)}
                        customer={customer}
                        favorites={favorites}
                        onSale={product.onSale}
                        price={product.price}
                        product={product}
                        sale={product.sale}
                        settings={settings}
                        sku={product.sku}
                        slug={product.slug}
                        t={t}
                        title={product.title}
                      />
                    </Col>
                  ) : (
                    <Col key={product._id} xs={24} sm={12} lg={8} className="mb-24">
                      <ProductShort
                        addProductToCart={() => addProductToCart(product)}
                        addProductToFavorites={() => addToFavorites(product._id)}
                        customer={customer}
                        favorites={favorites}
                        onSale={product.onSale}
                        price={product.price}
                        product={product}
                        sale={product.sale}
                        mediaType="desktop"
                        settings={settings}
                        sku={product.sku}
                        slug={product.slug}
                        t={t}
                        title={product.title}
                      />
                    </Col>
                  );
                })
              : null}
            {products.length === 0 && !loading ? (
              <Col xs={24} sm={24} lg={24} className="text-center pt-5">
                <strong>{t('noProductsFound')}</strong>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    favorites: state.customer.favorites,
    reduxProducts: state.cart.products
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (product, quantity, extraQuantity) =>
      dispatch(addToTempCart(product, quantity, extraQuantity)),
    addToFavorites: (favorite) => dispatch(addToFavorite(favorite))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDesktop);
