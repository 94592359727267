import axios from 'axios';
import actionTypes from './action.types';
import { apiUrlPublic } from '../../../config/config';

const {
  CATALOG_CHANGE_PAGE,
  CATALOG_CHANGE_PAGE_SIZE,
  CATALOG_FETCH_PRODUCTS_FAIL,
  CATALOG_FETCH_PRODUCTS_INIT,
  CATALOG_RESET,
  CATALOG_RESET_PAGE,
  CATALOG_RESET_RANGE,
  CATALOG_SET_PRODUCTS,
  CATALOG_UPDATE_REDRAW_KEY,
  CATALOG_CHANGE_SORT_ORDER
} = actionTypes;

export const changeCatalogPageSize = (pageSize, page) => ({
  type: CATALOG_CHANGE_PAGE_SIZE,
  pageSize,
  page
});

export const fetchProductsInit = () => ({
  type: CATALOG_FETCH_PRODUCTS_INIT
});

export const fetchProductsFail = () => ({
  type: CATALOG_FETCH_PRODUCTS_FAIL
});

export const resetCatalog = (parent) => ({
  type: CATALOG_RESET,
  parent
});

export const resetRange = () => ({
  type: CATALOG_RESET_RANGE
});

export const changeCatalogPage = (page) => ({
  type: CATALOG_CHANGE_PAGE,
  page
});

export const resetCatalogPage = () => ({
  type: CATALOG_RESET_PAGE
});

export const updateRedrawKey = () => ({
  type: CATALOG_UPDATE_REDRAW_KEY
});

export const changeSortOrder = (order, sort, orderType) => ({
  type: CATALOG_CHANGE_SORT_ORDER,
  order,
  sort,
  orderType
});

export const setCatalogProducts = (
  brands,
  categoryAncestors,
  categoryUrl,
  count,
  sort,
  page,
  pageCount,
  pageSize,
  pageSizes,
  products,
  order,
  tags,
  range
) => ({
  type: CATALOG_SET_PRODUCTS,
  brands,
  categoryAncestors,
  categoryUrl,
  count,
  sort,
  page,
  pageCount,
  pageSize,
  pageSizes,
  products,
  order,
  tags,
  range
});

export const fetchProducts = (
  categoryUrl,
  filters,
  order,
  page,
  pageSize,
  pageSizes,
  sort,
  parentCategoryUrl
) => {
  return async (dispatch) => {
    function onSuccess(response) {
      const {
        brands,
        categoryUrl: url,
        childrens,
        count,
        sort: productsSort,
        page: currentPage,
        pageCount,
        pageSize: size,
        pagesSizes: sizes,
        products,
        order: sortOrder,
        tags,
        responseRange
      } = response;

      dispatch(
        setCatalogProducts(
          brands,
          childrens,
          url,
          count,
          productsSort,
          currentPage,
          pageCount,
          size,
          sizes,
          products,
          sortOrder,
          tags,
          responseRange
        )
      );
      return response;
    }

    function onError(error) {
      console.log(error);
      return error;
    }

    try {
      dispatch(fetchProductsInit());
      const prdcts = await axios.get(
        `${apiUrlPublic}/product/category${categoryUrl}?pageSize=${pageSize}&sort=${sort}&order=${order}&skip=${page}&filters=${JSON.stringify(
          filters
        )}`
      );

      const cats = await axios.get(
        `${apiUrlPublic}/category/ancestors${parentCategoryUrl}`
      );
      const { childrens } = await cats.data;
      const { brands, range: responseRange, total, products, tags } = await prdcts.data;
      const { count } = total;
      return {
        catalog: onSuccess({
          brands,
          categoryUrl,
          childrens,
          count,
          order,
          page,
          total,
          pageSize,
          pageSizes,
          products,
          sort,
          tags,
          responseRange
        })
      };
    } catch (error) {
      dispatch(fetchProductsFail(error));
      return { error: onError(error) };
    }
  };
};
