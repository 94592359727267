import { Icon, Spin } from 'antd';
import axios from 'axios';
import Router, { withRouter } from 'next/router';
// import localeInfo from 'rc-pagination/lib/locale/el_GR';
import React from 'react';
import { connect } from 'react-redux';
import { apiUrlPublic } from '../../config/config';
import ProductCatalog from '../components/Catalog/ProductCatalog';
import { withTranslation } from '../i18n/i18n';
import {
  changeCatalogPage,
  changeCatalogPageSize,
  changeSortOrder,
  resetCatalogPage,
  updateRedrawKey
} from '../store/actions/catalog.actions';
import {
  setAttributesFilter,
  setOptionsFilter,
  setRangeFilter
} from '../store/actions/filters.actions';
import { setFilters } from '../utils/utils';

const loader = <Icon type="loading" style={{ fontSize: 40, color: '#679964' }} spin />;

export class CatalogContainer extends React.Component {
  static getInitialProps = async ({ query, asPath }) => {
    return {
      query,
      asPath,
      namespacesRequired: ['catalog']
    };
  };

  // eslint-disable-next-line react/sort-comp
  checkFilters = () => {
    const { filters } = this.props;
    if (filters.search) return true;
    return Object.keys(filters.pricesRange) > 0;
  };

  state = {
    attributes: [],
    categories: [],
    // count: 0,
    hasFilters: this.checkFilters(),
    loading: false,
    options: [],
    pricesRange: [],
    products: [],
    tags: []
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { query, asPath } = this.props;
    if (query) {
      if (
        query.location !== prevProps.query.location ||
        query.search !== prevProps.query.search
      ) {
        this.init(asPath);
      }
    }
  }

  init = async (asPath) => {
    const { query, setFilterPriceRange } = this.props;

    const parsedFilters = query ? (query.filters ? JSON.parse(query.filters) : '') : null;

    if (parsedFilters) {
      setFilterPriceRange(parsedFilters.pricesRange);
    }
    this.loadProducts(asPath, this.props.filters);
  };

  // eslint-disable-next-line consistent-return
  loadProducts = async (path, withFilters, resetPagination) => {
    const { sort } = this.props.catalog;
    const { changePage, search, setFilterPriceRange } = this.props;
    // avoid mutation of props
    const filters = { ...this.props.filters };
    const { query } = this.props.router;
    // const childrens = [];
    try {
      this.setState({ loading: true });

      if (withFilters) {
        const fetchProducts = await axios.get(
          `${apiUrlPublic}/product/catalog-e-asis/?location=${
            query.location ? query.location : ''
          }&sort=${sort}&filters=${JSON.stringify(filters)}${
            query.search ? `&search=${query.search}` : ''
          }`
        );

        if (resetPagination) {
          changePage(1);
        }

        const {
          attributes,
          options,
          pricesRange,
          products,
          // total,
          categories
        } = await fetchProducts.data;
        // const { count } = total;
        this.setState({
          attributes,
          // childrens,
          categories,
          // count,
          hasFilters: true,
          loading: false,
          options,
          pricesRange,
          products
        });

        /*        console.log('[FILTERS] in Catalog', filters);
        console.log('[PRICES RANGE] in Catalog', pricesRange); */

        await setFilterPriceRange({
          max:
            filters.pricesRange.max > pricesRange.max
              ? pricesRange.max
              : filters.pricesRange.max,
          min:
            filters.pricesRange.min < pricesRange.min
              ? pricesRange.min
              : filters.pricesRange.min
        });

        const newFilters = { ...this.props.filters };
        // eslint-disable-next-line no-underscore-dangle
        delete newFilters._persist;

        return Router.push(
          `/catalog?location=${query.location ? query.location : ''}${setFilters(
            newFilters
          )}${search?.text ? (search.text ? `&search=${search.text}` : '') : ''}`,
          `/catalog?location=${query.location ? query.location : ''}${setFilters(
            newFilters
          )}${search?.text ? (search.text ? `&search=${search.text}` : '') : ''}`,
          { shallow: false }
        );
      } else {
        const fetchProducts = await axios.get(
          `${apiUrlPublic}/product/catalog-e-asis/?location=${
            query.location ? query.location : ''
          }&sort=${sort}&filters=${JSON.stringify(filters)}${
            query.search ? `&search=${query.search}` : ''
          }`
        );

        const {
          attributes,
          pricesRange,
          products,
          // total,
          categories
        } = await fetchProducts.data;
        //  const { count } = total;
        return this.setState({
          attributes,
          // childrens,
          categories,
          // count,
          hasFilters: false,
          loading: false,
          pricesRange,
          products
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log('ERROR IN FETCH PRODUCTS ', error.response ? error.response : error);
    }
  };

  setSortOrder = async (order) => {
    const { changeSortOrder: changeOrder } = this.props;
    if (order) {
      switch (order) {
        case 'priceDescending':
          changeOrder('price', -1, 'priceDescending');
          break;
        case 'priceAscending':
          changeOrder('price', 1, 'priceAscending');
          break;
        case 'alphaDescending':
          changeOrder('title', -1, 'alphaDescending');
          break;
        case 'alphaAscending':
          changeOrder('title', 1, 'alphaAscending');
          break;
        case 'ratings':
          changeOrder('ratings', -1, 'ratings');
          break;
        default:
          changeOrder('price', -1, 'priceAscending');
      }
    }
  };

  applyFilters = async (order) => {
    this.setSortOrder(order);
    setTimeout(async () => {
      await this.loadProducts(null, true, true);
    }, 0);
  };

  /*  onChangePage = async (page) => {
    const { hasFilters } = this.state;
    const { changePage } = this.props;
    await changePage(page);

    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
    await this.loadProducts(null, hasFilters);
  };

  onSizeChange = async (current, pageSize) => {
    const { hasFilters } = this.state;
    const { changePageSize } = this.props;
    await changePageSize(pageSize, current);

    // if (pageSize === 12) {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth'
    //   });
    // }
    await this.loadProducts(null, hasFilters, 'pagination');
  }; */

  render() {
    const {
      catalog,
      filters,
      redrawProductsKey,
      resetProductRange,
      settings,
      t
    } = this.props;
    const {
      attributes,
      // childrens,s
      categories,
      // count,
      hasFilters,
      loading,
      options,
      pricesRange,
      products,
      sortOrder,
      tags
    } = this.state;

    //  console.log(`test: ${categories}`);

    return (
      <>
        <div className="home-container">
          <Spin
            indicator={loader}
            spinning={loading}
            wrapperClassName={loading ? 'spin-wrapper' : ''}
          >
            <ProductCatalog
              applyFilters={this.applyFilters}
              attributes={attributes}
              catalog={catalog}
              categories={categories}
              categoryUrl={catalog.categoryUrl}
              changeSortOrder={this.applyFilters}
              filters={filters}
              hasFilters={hasFilters}
              loading={loading}
              options={options}
              pricesRange={pricesRange}
              products={products}
              redrawKey={() => redrawProductsKey()}
              resetProductRange={() => resetProductRange()}
              sortOrder={sortOrder}
              settings={settings}
              t={t}
              tags={tags}
            />
          </Spin>
          {/* !loading ? (
            <div className="pagination-wrapper">
              <Pagination
                current={catalog.page}
                defaultCurrent={1}
                total={count}
                showSizeChanger
                showLessItems
                locale={localeInfo}
                onChange={this.onChangePage}
                pageSizeOptions={catalog.pageSizes}
                pageSize={Number(catalog.pageSize)}
                onShowSizeChange={this.onSizeChange}
              />
            </div>
          ) : null} */}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (page) => dispatch(changeCatalogPage(page)),
    changePageSize: (pageSize, currentPage) =>
      dispatch(changeCatalogPageSize(pageSize, currentPage)),
    changeSortOrder: (order, sort, orderType) =>
      dispatch(changeSortOrder(order, sort, orderType)),
    redrawProductsKey: () => dispatch(updateRedrawKey()),
    resetPage: () => dispatch(resetCatalogPage()),
    setFilterAttributes: (attributes) => dispatch(setAttributesFilter(attributes)),
    setFilterOptions: (options) => dispatch(setOptionsFilter(options)),
    setFilterPriceRange: (pricesRange) => dispatch(setRangeFilter(pricesRange))
  };
};

const mapStateToProps = (state) => {
  return {
    catalog: state.catalog,
    filters: state.filters,
    search: state.search,
    settings: state.settings.settings
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation('catalog')(CatalogContainer))
);
