import actionTypes from './action.types';

const {
  FILTERS_RESET_ALL,
  FILTERS_RESET_ATTRIBUTES,
  FILTERS_RESET_BRANDS,
  FILTERS_RESET_OPTIONS,
  FILTERS_RESET_RANGE,
  FILTERS_RESET_TAGS,
  FILTERS_SET_ATTRIBUTES,
  FILTERS_SET_BRANDS,
  FILTERS_SET_OPTIONS,
  FILTERS_SET_RANGE,
  FILTERS_SET_TAGS
} = actionTypes;

// RESET
export const resetAllFilters = () => ({
  type: FILTERS_RESET_ALL
});

export const resetAttributesFilter = () => ({
  type: FILTERS_RESET_ATTRIBUTES
});

export const resetBrandsFilter = () => ({
  type: FILTERS_RESET_BRANDS
});

export const resetOptionsFilter = () => ({
  type: FILTERS_RESET_OPTIONS
});

export const resetRangeFilter = () => ({
  type: FILTERS_RESET_RANGE
});

export const resetTagsFilter = () => ({
  type: FILTERS_RESET_TAGS
});

// SET
export const setAttributesFilter = (attributes) => ({
  type: FILTERS_SET_ATTRIBUTES,
  attributes
});

export const setBrandsFilter = (brands) => ({
  type: FILTERS_SET_BRANDS,
  brands
});

export const setOptionsFilter = (options) => ({
  type: FILTERS_SET_OPTIONS,
  options
});

export const setRangeFilter = (pricesRange) => ({
  type: FILTERS_SET_RANGE,
  pricesRange
});

export const setTagsFilter = (tags) => ({
  type: FILTERS_SET_TAGS,
  tags
});
