import { Col, Row } from 'antd';
import React from 'react';
import InputRange from 'react-hgs-input-range';

const CatalogPriceRange = ({
  filters,
  onChangePriceRange,
  onChangePriceRangeComplete,
  pricesRange,
  settings
}) => {
  // const numInputRangeSteps = 5;

  return (
    <>
      <Col className="mt-3">
        <span className="text-16-bold">ΦΙΛΤΡΑ ΑΠΟΤΕΛΕΣΜΑΤΩΝ</span>
      </Col>
      <Col>
        <Row>
          <Col className="mt-50 mb-10">
            <span className="text-18-normal">Εύρος Τιμής</span>
          </Col>
          <Col className="mt-25 pl-2 pr-2">
            <InputRange
              allowSameValues
              formatLabel={(value) => `${value}${settings.currency_symbol}`}
              maxValue={Math.ceil(pricesRange?.max || 0.01)}
              minValue={Math.floor(pricesRange?.min || 0)}
              // step={(pricesRange.max - pricesRange.min) / numInputRangeSteps}
              step={2}
              value={{
                max: filters?.pricesRange?.max || pricesRange?.max || 0,
                min: filters?.pricesRange?.min || pricesRange?.min || 0
              }}
              onChange={onChangePriceRange}
              draggableTrack
              onChangeComplete={onChangePriceRangeComplete}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CatalogPriceRange;
