import { Button, Col, Icon, Row, Spin } from 'antd';
import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import { formatCurrency, getImageLG } from '../../../utils/utils';
import '../Mobile/styles/productLong.less';

const ProductLong = ({
  addProductToCart,
  addProductToFavorites,
  favorites,
  product,
  settings,
  t
}) => {
  const isFavorite = favorites.some((favorite) => favorite === product._id);
  const [isProductNew, setProductNew] = useState(false);
  const [loading] = useState(false);
  const loader = (
    <Icon
      type="loading"
      style={{ fontSize: 30, color: '#679964', marginLeft: '40px' }}
      spin
    />
  );

  useEffect(() => {
    if (product.createdAt === undefined) return;

    const currentDate = () => {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();

      if (day < 10) {
        day = `0${day}`;
      }
      if (month < 10) {
        month = `0${month}`;
      }
      const fullDate = `${month}/${day}/${year}`;
      return fullDate;
    };

    const productCreatedDate = () => {
      if (product.createdAt === undefined) return;

      const index = product.createdAt.indexOf('T');
      let createdAt = product.createdAt.slice(0, index);
      const year = createdAt.slice(0, 4);
      const month = createdAt.slice(5, 7);
      const day = createdAt.slice(8);
      createdAt = `${month}/${day}/${year}`;
      return createdAt;
    };

    const dateProductCreated = new Date(productCreatedDate());
    const dateToday = new Date(currentDate());

    const timeDifference = dateToday.getTime() - dateProductCreated.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    if (daysDifference < 30) {
      setProductNew(true);
    }
  }, []);

  const getAvaliability = (value) => {
    // console.log(value)
    // eslint-disable-next-line default-case
    switch (value) {
      case 'direct':
        return t('Availability.direct');
      case 'order':
        return t('Availability.order');
      case '1-3':
        return t('Availability.1-3');
      case '4-10':
        return t('Availability.4-10');
    }
  };
  // console.log(product);
  return (
    <>
      {/* product image */}
      <Row type="flex" className="width-full">
        <Col className="img-container-long mb-30" span={24} align="center">
          <Link
            href={`/product?slug=${encodeURIComponent(product.slug)}`}
            as={`/product/${product.slug}`}
          >
            <a className="width-full">
              <img
                alt="product"
                className="image-fluid product-img-long"
                src={getImageLG(product)}
              />
            </a>
          </Link>
        </Col>
      </Row>
      {/* product information */}
      <Row gutter={30} type="flex" justify="space-between" className="width-full mt-30">
        <Col xs={24} md={12} lg={12} xl={16}>
          {/* limitedQuantity */}
          <Row className="mb-10">
            {product.stock < 3 && product.stock > 0 ? (
              <span className="limited-quantity">{t('limitedQuantity')}</span>
            ) : null}
            {product.stock === 0 ? (
              <span className="text-14-red">{t('outOfStock')}</span>
            ) : null}
          </Row>
          {/* product title */}
          <Row>
            <Link
              href={`/product?slug=${encodeURIComponent(product.slug)}`}
              as={`/product/${product.slug}`}
            >
              <a className="text-18-bold-link">{product.title}</a>
            </Link>
          </Row>
          <Row className="mt-10">
            <Col span={24}>
              {product.sku ? (
                <div className="text-14-mute">
                  {t('sku')}
                  {product.sku}
                </div>
              ) : null}
            </Col>
            <Col>
              <span className="text-14-normal">
                {t(`Availability.${product.availability?.value}`)}
              </span>
            </Col>
            {isProductNew && (
              <Col className="subtitle-text bold">
                <p className="subtitle-text bold">{t('newProduct')}</p>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={24} md={12} lg={12} xl={8} className="pr-0">
          {/* diathesimo katopin ekptopi */}
          <Row className="mobile-available" type="flex" justify="start">
            <Col xs={18} sm={19} className="mb-10">
              <span className="text-12-normal">
                {getAvaliability(product.availability?.label)}
              </span>
            </Col>
            <Col className="product-long-dicount-container" span={4}>
              {product.onSale.value ? (
                <div className="circle-item text-white">
                  -{Math.round(product.sale.percentage)}%
                </div>
              ) : null}
            </Col>
          </Row>
          {/* product price */}
          <Row type="flex" align="bottom">
            {product.onSale.value ? (
              <>
                <div className="line-through">
                  <span className="text-16-normal m-0 text-light">{t('from')}</span>
                  <span className="text-20-mute text-light pr-2">
                    {formatCurrency(product.price, settings)}
                  </span>
                </div>
                <span className="text-24-mute text-black pl-1">
                  {formatCurrency(product.sale.salePrice, settings).split(',')[0]},
                </span>
                <span className="text-24-mute text-black pr-1">
                  {
                    formatCurrency(product.sale.salePrice, settings)
                      .split(',')[1]
                      .split(' ')[0]
                  }
                </span>
                <span className="text-24-mute text-black pr-1 ">
                  {formatCurrency(product.sale.salePrice, settings).split(' ')[1]}
                </span>
              </>
            ) : (
              <>
                <span className="text-24-mute text-black pl-1">
                  {formatCurrency(product.price, settings).split(',')[0]},
                </span>
                <span className="text-24-mute text-black pr-1">
                  {formatCurrency(product.price, settings).split(',')[1].split(' ')[0]}
                </span>
                <span className="text-24-mute text-black pr-1 ">
                  {formatCurrency(product.price, settings).split(' ')[1]}
                </span>
              </>
            )}
          </Row>
          {/* add to cart add to fav */}
          <Row className="mt-10" justify="space-between" align="bottom" type="flex">
            <Col>
              <Row type="flex" justify="center">
                <Col className="mr-10">
                  {!isFavorite ? (
                    <Button
                      htmlType="button"
                      className="outlined-button-product-icon"
                      onClick={() => addProductToFavorites(product)}
                    >
                      <Icon type="heart" style={{ fontSize: 18 }} />
                    </Button>
                  ) : (
                    <Button
                      htmlType="button"
                      className="outlined-button-product-icon"
                      onClick={() => addProductToFavorites(product)}
                    >
                      <Icon
                        style={{ color: 'red', fontSize: 18 }}
                        type="heart"
                        theme="filled"
                      />
                    </Button>
                  )}
                </Col>
                <Col>
                  {/* <Button onClick={addProductToCart} className="cart-button-product-icon">
                    <Icon type="shopping-cart" style={{ fontSize: 22 }} />
                  </Button> */}
                  {product.variants !== undefined && product.variants.length > 0 ? (
                    <Col>
                      {loading ? (
                        <Spin indicator={loader} />
                      ) : (
                        <Link
                          href={`/product?slug=${encodeURIComponent(product.slug)}`}
                          as={`/product/${product.slug}`}
                        >
                          <Button
                            htmlType="button"
                            disabled={product.availability.value === 'notAvailable'}
                            className="cart-button-product-icon"
                          >
                            <Icon type="shopping-cart" style={{ fontSize: 22 }} />
                          </Button>
                        </Link>
                      )}
                    </Col>
                  ) : (
                    <Col>
                      <Button
                        htmlType="button"
                        onClick={addProductToCart}
                        disabled={product.availability.value === 'notAvailable'}
                        className="cart-button-product-icon"
                      >
                        <Icon type="shopping-cart" style={{ fontSize: 22 }} />
                      </Button>
                    </Col>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProductLong;
